/* eslint-disable react/no-children-prop */
import * as React from 'react';
import Container from '@material-ui/core/Container';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import Seo from '../components/Seo';
import { LocationLanguageContextType } from '../typings';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { Languages } from '../utils/getAllSupportedLanguages';

interface StrapiErrorPage {
  Description: string;
  locale: string;
}

interface ErrorPageQueryProps {
  errorPageContent: StrapiErrorPage;
}

export const PureErrorPage: React.FC<ErrorPageQueryProps> = ({ errorPageContent }) => (
  <>
    {errorPageContent ? (
      <>
        <Seo title='Page not found' description='Page requested was not found' isTemplatePage={false} />
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <br />
          <br />
          <StaticImage
            layout='constrained'
            placeholder='tracedSVG'
            src='../assets/404/construction-placeholder.svg'
            alt='construction building placeholder img'
          />
          <Typography component='div'>
            <ReactMarkdown children={errorPageContent.Description} className='markdown-text' />
          </Typography>
        </Container>
      </>
    ) : (
      <Typography variant='body1' />
    )}
  </>
);

const errorPageQuery = graphql`
  query ErrorPageQuery {
    allStrapiErrorPage {
      nodes {
        Description
        locale
      }
    }
  }
`;

export const ErrorPage: React.FC<{}> = () => {
  const { allStrapiErrorPage } = useStaticQuery(errorPageQuery);

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const errorPageContent = allStrapiErrorPage.nodes.find((x: any) => x.locale === language);

  return <PureErrorPage errorPageContent={errorPageContent} />;
};

export default ErrorPage;
